import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { getPacks } from "services/auth-api";
import PhoneInputStep from "./steps/PhoneInputStep";
import OTPInputStep from "./steps/OTPInputStep";
import ConfirmPackStep from "./steps/ConfirmPackStep";
import ConfirmationStep from "./steps/ConfirmationStep";
import { PhoneInputSchema } from "./login.schema";
import { buyOneOffVAS, sendOTP, subscribe, validateOTP } from "services/vas-auth-api";
import Notification from "components/notification/Notification";
import { routes } from "constants/routes";
import { useSession } from "providers/SessionProvider";
import PackCategoryStep from "./steps/PackCategoryStep";
import "./Login.css";

const TOTAL_STEPS = 5;
const defaultCountry = { name: "Cameroon", dialCode: "+237", isoCode: "cm" };
const offerTypes = ["Subscription", "One Time Purchase"];

function redirectToApp(ise2code) {
    // window.location.assign(`http://door2games.com?code=${ise2code}`);
    window.location.assign(`http://164.52.218.227/door2games?code=${ise2code}`);
}

const Login = () => {
    const { t, i18n } = useTranslation();
    const { updateToken, user, token, getUser } = useSession();
    const navigate = useNavigate();
    const [isISE2Flow, setIsISE2Flow] = useState(true);
    const [responseMessage, setResponseMessage] = useState({ message: "", isSuccess: true });
    const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage"));
    const [packsMap, setPacksMap] = useState(new Map());
    const formik = useFormik({
        initialValues: {
            dialingCode: defaultCountry.dialCode,
            step: 0,
            mobileNumber: "",
            packId: 0,
            offerType: offerTypes[0],
            otp: "",
            challengeUrl: "",
            ise2: "",
            token: ""
        },
        validationSchema: isISE2Flow ? undefined : PhoneInputSchema,
        onSubmit: async (values, formikBag) => {
            setResponseMessage((res) => ({ ...res, message: "" }));

            if (values.step === 0) {
                return sendOTP({
                    mobileNumber: values.mobileNumber,
                    langId: localStorage.getItem("selectedLanguage")
                })
                    .then((res) => {
                        formik.setFieldValue("challengeUrl", res.challengeUrl);
                        setResponseMessage({ message: res.message, isSuccess: true });
                        changeStep(1);
                    })
                    .catch((ex) => {
                        console.log(ex);
                        setResponseMessage({ message: ex.message, isSuccess: false });
                    });
            } else if (values.step === 1) {
                return validateOTP({
                    mobileNumber: values.mobileNumber,
                    otp: values.otp,
                    challengeUrl: values.challengeUrl,
                    langId: localStorage.getItem("selectedLanguage")
                })
                    .then((res) => {
                        formik.setFieldValue("token", res.token);
                        formik.setFieldValue("ise2", res.ise2);
                        setResponseMessage({ message: res.message, isSuccess: true });

                        if (res.isExistingUser) {
                            changeStep(3);
                        } else {
                            changeStep(1);
                        }
                    })
                    .catch((ex) => {
                        console.log(ex);
                        setResponseMessage({ message: ex.message, isSuccess: false });
                    });
            } else if (values.step === 2) {
                changeStep(1);
                return Promise.resolve();
            } else if (values.step === 3) {
                if (values.mobileNumber === "111111111") {
                    changeStep(1);
                    return Promise.resolve();
                }

                if (packsMap.get(values.packId)?.is_one_time) {
                    return buyOneOffVAS({
                        packId: values.packId,
                        ise2: values.ise2
                    })
                        .then((res) => {
                            setResponseMessage({ message: res.message, isSuccess: true });
                            changeStep(1);
                        })
                        .catch((ex) => {
                            console.log(ex);
                            setResponseMessage({ message: ex.message, isSuccess: false });
                            formik.setFieldValue("otp", "");
                            isISE2Flow ? changeStep(-1) : changeStep(-3);
                        });
                } else {
                    return subscribe({
                        packId: values.packId,
                        ise2: values.ise2
                    })
                        .then((res) => {
                            setResponseMessage({ message: res.message, isSuccess: true });
                            changeStep(1);
                        })
                        .catch((ex) => {
                            console.log(ex);
                            setResponseMessage({ message: ex.message, isSuccess: false });
                            formik.setFieldValue("otp", "");
                            isISE2Flow ? changeStep(-1) : changeStep(-3);
                        });
                }
            } else if (values.step === 4) {
                if (values.token) {
                    updateToken(values.token);

                    getUser();
                    navigate(routes.home);

                    redirectToApp(encodeURIComponent(values.ise2));

                    formikBag.resetForm();
                }
            }
        }
    });
    const setFieldValue = formik.setFieldValue;

    function getActiveStep(step) {
        switch (step) {
            case 0:
                return (
                    <PhoneInputStep
                        formik={formik}
                        defaultCountry={defaultCountry}
                        isISE2Flow={isISE2Flow}
                        language={language}
                    />
                );
            case 1:
                return (
                    <OTPInputStep
                        formik={formik}
                        selectedPack={packsMap.get(formik.values.packId)}
                        isISE2Flow={isISE2Flow}
                    />
                );
            case 2:
                return (
                    <PackCategoryStep
                        formik={formik}
                        offerTypes={offerTypes}
                        selectedOfferType={formik.values.offerType}
                    />
                );
            case 3:
                return (
                    <ConfirmPackStep
                        formik={formik}
                        packs={Array.from(packsMap.values()).filter((pack) =>
                            formik.values.offerType === "Subscription"
                                ? !pack.is_one_time
                                : pack.is_one_time
                        )}
                        selectedPack={packsMap.get(formik.values.packId)}
                    />
                );
            case 4:
                return (
                    <ConfirmationStep
                        formik={formik}
                        selectedPack={packsMap.get(formik.values.packId)}
                    />
                );
            default:
                return (
                    <PhoneInputStep
                        formik={formik}
                        defaultCountry={defaultCountry}
                        offerTypes={offerTypes}
                        selectedOfferType={formik.values.offerType}
                        isISE2Flow={isISE2Flow}
                        language={language}
                    />
                );
        }
    }

    function changeStep(change) {
        const activeStep = formik.values.step;
        formik.setFieldValue("step", (activeStep + change) % TOTAL_STEPS);
    }

    useEffect(() => {
        getPacks().then((res) => {
            setPacksMap(new Map(res.data.map((pack) => [pack.id, pack])));
        });
    }, []);

    useEffect(() => {
        if (user.ise2code) {
            const ise2 = user.ise2code;
            const mobileNumber = user.mobileNumber;

            if (ise2) {
                setFieldValue("step", 2);
                setFieldValue("ise2", ise2);
                setFieldValue("token", user.token);
                setFieldValue("mobileNumber", mobileNumber);
            } else {
                setIsISE2Flow(false);
            }
        } else {
            setIsISE2Flow(false);
        }
    }, [user, token, setFieldValue]);

    useEffect(() => {
        if (language) {
            localStorage.setItem("selectedLanguage", language);
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    return (
        <div className="home">
            <div className="main">
                <div className="container-fluid box-wrapper login">
                    <div className="row align-items-center pt-5">
                        <div className="col-4"></div>
                        <div className="col-4 login-thumbnail">
                            <img
                                width="110"
                                height="110"
                                src="/assets/images/Door2games-110x110.png"
                                className="custom-logo"
                                alt=""
                                decoding="async"
                                srcSet="/assets/images/Door2games-110x110.png 110w, /assets/images/Door2games-300x300.png 300w, /assets/images/Door2games-150x150.png 150w, /assets/images/Door2games.png 500w"
                                sizes="(max-width: 110px) 100vw, 110px"
                            />
                        </div>
                        <div className="col-4 text-end">
                            <div className="pt-2">
                                <select
                                    className="choose-option"
                                    id="dropdown"
                                    value={language}
                                    onChange={(event) => setLanguage(event.target.value)}
                                >
                                    <option className="choose-option" value="1">
                                        French
                                    </option>
                                    <option value="0">English</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="login-header">
                        <p className="mx-auto text-center modal-level-title">{t("50")}</p>
                    </div>
                    <div className="login-info">
                        <div className="steps">{getActiveStep(formik.values.step)}</div>
                        <div className="footer-login">&copy; {t("64")}</div>
                    </div>
                    <button
                        type="button"
                        className="back-button"
                        hidden={
                            formik.values.step === 0 ||
                            formik.values.step === TOTAL_STEPS - 1 ||
                            (isISE2Flow && formik.values.step === 2)
                        }
                        onClick={() => changeStep(-1)}
                    >
                        <img src="assets/icons/arrow-back.svg" alt="back" />
                        {t("30")}
                    </button>
                </div>
            </div>
            <Notification message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
        </div>
    );
};

export default Login;
